import { H2 } from "~/atoms/typography/headings";
import { PersonalDetailsCard } from "~/pages/client/settings/personalDetailsCard";
import { ManageEmailCard } from "~/pages/client/settings/manageEmailCard";
import { ManagePasswordCard } from "~/pages/client/settings/managePasswordCard";
import { DashboardSidebar } from "~/templates/dashboardTemplates";
import { useGetUserInfo } from "~/hooks/auth/useUserInfo";

const Settings = () => {
  const { userInfo } = useGetUserInfo();
  console.log("userInfo: ", userInfo);
  return (
    <DashboardSidebar>
      <div className="sm:px-6 lg:px-8 grid grid-cols-1">
        <H2>Account Settings</H2>
        <PersonalDetailsCard />
        <ManageEmailCard />
        <ManagePasswordCard />
      </div>
    </DashboardSidebar>
  );
};

export default Settings;
