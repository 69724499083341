import { ClientCreditCards } from "~/pages/client/billing/ClientCreditCards";
import { InvoicesTable } from "~/pages/client/billing/invoicesTable";
import { DashboardSidebar } from "~/templates/dashboardTemplates";

const Invoices = () => {
  return (
    <DashboardSidebar>
      <div className="sm:px-6 lg:px-8 grid grid-cols-1">
        <ClientCreditCards />
        <InvoicesTable />
      </div>
    </DashboardSidebar>
  );
};

export default Invoices;
