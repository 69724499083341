import { Dashboard } from "~/templates/dashboardTemplates";
import { SearchDomain } from "~/organisms/searchDomain";
import { TemporaryDrawer } from "~/molecules/temporaryDrawer";
import { Input } from "~/atoms/forms/Input";

const Domains = () => {
  return (
    <Dashboard>
      <SearchDomain />
      <TemporaryDrawer>
        <div className="bg-white shadow rounded-lg p-6 mt-8">
          <h3 className="mb-8">
            Create a contact to link it to domain purchase
          </h3>
          <div className="grid lg:grid-cols-2 gap-6">
            <Input labelName="name" required />
            <Input labelName="address" required />
            <Input labelName="company name" />
            <Input labelName="city" required />
            <Input labelName="postal code" required />
            <Input labelName="country" required />
            <Input labelName="state" required />
            <Input labelName="phone number" required />
            <Input labelName="password" required />
            <Input labelName="confirm password" required />
          </div>
          {/* TODO: agregar ligas de los agreements y crear componentes de agreements */}
          <div className="flex items-start mb-2 mt-8">
            <div className="flex items-center h-5">
              <input
                aria-describedby="remember"
                type="checkbox"
                className="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded"
                required
              />
            </div>
            <div className="text-sm ml-3">
              <label htmlFor="agreement" className="font-medium text-gray-900">
                By creating an account you agree to our Terms {"&"} Conditions
                and acknowledge receipt of the Privacy Policy
              </label>
            </div>
          </div>
          <button className="mt-2 rounded text-gray-100 px-3 py-1 bg-blue-500 hover:shadow-inner hover:bg-blue-700 transition-all duration-300">
            Create contact
          </button>
        </div>
      </TemporaryDrawer>
    </Dashboard>
  );
};

export default Domains;
