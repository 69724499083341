import { Dashboard } from "~/templates/dashboardTemplates";
import { ChooseAnApp } from "~/organisms/chooseAnApp";

const Marketplace = () => {
  return (
    <Dashboard>
      <ChooseAnApp />
    </Dashboard>
  );
};

export default Marketplace;
