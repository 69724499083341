import { H2 } from "~/atoms/typography/headings";
import {
  DomainCard,
  CpanelCard,
} from "~/pages/client/marketplace/marketplaceCards";

export const ChooseAnApp = () => {
  return (
    <div className="py-2 align-middle flex flex-col">
      <H2>Choose an app or register a domain</H2>
      <section className="flex">
        <DomainCard />
        <CpanelCard />
      </section>
    </div>
  );
};
