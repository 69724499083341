import { SubscriptionsTable } from "~/pages/client/subscriptionsServices/subscriptionsTable";
import { DashboardSidebar } from "~/templates/dashboardTemplates";

const Billing = () => {
  return (
    <DashboardSidebar>
      <div className="sm:px-6 lg:px-8 grid grid-cols-1">
        <SubscriptionsTable />
      </div>
    </DashboardSidebar>
  );
};

export default Billing;
