import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { RecoilRoot } from "recoil";
import { client } from "~/ApolloClient";
import { ApolloProvider } from "~/apollo-dependencies";
// providers
import { AuthorizationProvider } from "~/state/AuthorizationProvider";
// import { ProfileProvider } from "./state/ProfileState";
// import { StepsCpanelProvider } from "./state/StepsCpanelState.js";
// helpers
// auth
// import AuthAccount from "./pages/auth/login";
// import CreateAccount from "./pages/auth/createaccount";
// import ConfirmationCode from "./pages/auth/confirmationcode";
// import ForgotPasswordSubmitCode from "./pages/auth/forgotpassword/ForgotPaswordSubmitCode";
// // layouts
// import DashboardLayout from "./layouts/dashboard";
// components
import { TopNavClient } from "./organisms/topNav";
// pages
import Login from "~/pages/auth/login";
import Overview from "~/pages/client/overview";
import Marketplace from "~/pages/client/marketplace";
import Settings from "~/pages/client/settings";
import Billing from "~/pages/client/subscriptionsServices";
import Invoices from "~/pages/client/billing";
import Domains from "~/pages/client/domains";
import "./App.css";

const App = () => {
  return (
    <ApolloProvider client={client}>
      <Router>
        <RecoilRoot>
          <AuthorizationProvider>
            <TopNavClient />
            <Switch>
              <Route exact path="/">
                <Redirect to="/overview" />
              </Route>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/overview">
                <Overview />
              </Route>
              <Route path="/marketplace">
                <Marketplace />
              </Route>
              <Route path="/domains">
                <Domains />
              </Route>
              <Route exact path="/settings">
                <Settings />
              </Route>
              <Route path="/settings/subscriptions-services">
                <Billing />
              </Route>
              <Route path="/settings/billing">
                <Invoices />
              </Route>
            </Switch>
          </AuthorizationProvider>
        </RecoilRoot>
      </Router>
    </ApolloProvider>
  );
};

export default App;
