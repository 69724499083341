import { gql, useQuery } from "~/apollo-dependencies";

const GET_USER_INFO = gql`
  query getUserInfo {
    users {
      id
      userProfile {
        id
        fullname
      }
    }
  }
`;

export const useGetUserInfo = (options) => {
  const query = useQuery(GET_USER_INFO, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    ...options,
  });

  const userProfile = query?.data?.users[0].userProfile;
  return { query, userProfile };
};
