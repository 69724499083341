import { H2 } from "~/atoms/typography/headings";
import { useSubscriptions } from "~/hooks/billing/useSubscriptions";

export const YourDomains = () => {
  const { subscriptions } = useSubscriptions();

  if (!subscriptions) return "loading";
  return (
    <div className="py-2 align-middle flex flex-col">
      <div className="flex flex-row items-center h-20">
        <H2 className="flex-grow mt-6">Domains</H2>
        <section className="button-group flex flex-row">
          <button
            className="px-4 h-8 mr-4 flex items-center rounded-md bg-black text-white"
            type="submit"
          >
            Buy
          </button>
          <button
            className="px-4 h-8 mr-4 flex items-center rounded-md bg-black text-white"
            type="submit"
          >
            Add
          </button>
          <button
            className="px-4 h-8 mr-4 flex items-center rounded-md bg-black text-white"
            type="submit"
          >
            Transfer in
          </button>
        </section>
      </div>

      <table className="divide-y divide-gray-200 shadow border-b border-gray-200 sm:rounded-lg max-w-sm">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left  text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              DOMAIN
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left hidden sm:block text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              REGISTRAR
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              NAMESERVERS
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              EXPIRATION DATE
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              ...
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {subscriptions.map((subscription, index) => (
            <tr key={`${subscription.name}-${index}`}>
              <td className="px-6 py-4 whitespace-nowrap">
                <p className="text-sm text-gray-900">vivetunube.com</p>
              </td>
              <td className="px-6 py-4 whitespace-nowrap hidden sm:block">
                VTN
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <p className="text-sm text-gray-900">THIRD PARTY</p>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <p className="text-sm text-gray-900">12 sept 2021</p>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <p className="text-sm text-gray-900">...</p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
