import { gql, useMutation } from "~/apollo-dependencies";

const UPSERT_USER = gql`
  mutation upsertUser($id: uuid, $email: String) {
    insert_users(
      objects: { id: $id, email: $email }
      on_conflict: { constraint: users_pkey, update_columns: [email] }
    ) {
      returning {
        id
        email
      }
    }
  }
`;

export const useUpsertUser = () => {
  const [upsert_user] = useMutation(UPSERT_USER);
  return { upsert_user };
};
