import { YourDomains } from "./yourDomains";
import { Dashboard } from "~/templates/dashboardTemplates";

const Overview = () => {
  return (
    <Dashboard>
      <YourDomains />
    </Dashboard>
  );
};

export default Overview;
