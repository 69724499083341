import { useState, useEffect, useMemo } from "react";
import { useQuery } from "~/helpers/customHooks";
import { useHistory } from "react-router-dom";
import { whiteListedDomains } from "~/helpers/domains/whiteListedDomains";
// import { useUserDomainInfo } from "~/hooks/domains/useUserDomainInfo";
import { debounce } from "lodash";
import axios from "axios";

interface manageDomainSearch {
  value?: string;
}

interface getDomainResults {
  domain: string;
  tld: string;
}

export const useSearchDomain = () => {
  const [domainSearch, setDomainSearch] = useState("");
  const [domainsList, setDomainsList] = useState([]);

  // const { authUserId } = useUserDomainInfo();

  let history = useHistory();
  let query = useQuery();

  const manageDomainSearch = async ({ value = "" }: manageDomainSearch) => {
    let searchedValue = value;
    setDomainSearch(searchedValue);
    if (searchedValue === "") {
      setDomainSearch("");
      history.push(`/domains`);
      return;
    }

    setDomainSearch(searchedValue);
    if (searchedValue.includes(" ")) {
      // TODO: lets not permit more than one . consecutive
      searchedValue = searchedValue.replace(/\s/g, "");
    }

    let tldIndexPosition = searchedValue.search(whiteListedDomains);
    if (tldIndexPosition !== -1) {
      let tld = searchedValue.slice(tldIndexPosition, searchedValue.length);
      let domain = searchedValue.slice(0, tldIndexPosition);

      domain = domain.replace(".", "");

      history.push(`/domains?q=${domain}&tlds=${tld}`);

      let domainsAvailableRaw = await getDomainResults({ domain, tld });
      let domainsAvailable = JSON.parse(domainsAvailableRaw);

      setDomainsList(domainsAvailable);
    } else {
      let domain = searchedValue;
      domain = domain.includes(".") ? domain.split(".")[0] : domain;

      history.push(`/domains?q=${domain}&tlds=com`);

      let domainsAvailableRaw = await getDomainResults({ domain, tld: "com" });
      console.log("domainsAvailableRaw: ", domainsAvailableRaw);
      let domainsAvailable = JSON.parse(domainsAvailableRaw);

      setDomainsList(domainsAvailable);
    }
  };

  const debouncedDomainSearch = useMemo(
    () => debounce(manageDomainSearch, 300),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getDomainResults = async ({ domain, tld }: getDomainResults) => {
    const authResellerUserId = "593059";
    // TODO: how to get the correct resellerclub user?
    // TODO: is user sending more than one TLD? should we manage send tlds from here?
    // TODO: send resellerid from database
    // TODO: relational database user with resellerid
    let getDomainsAvailableUrl = `http://localhost:3000/api/domains/available?auth-userid=${authResellerUserId}&domain-name=${domain}&tlds=${tld}`;

    var config = {
      method: "get",
      url: getDomainsAvailableUrl,
    };
    // @ts-ignore
    const response = await axios(config);
    const resdata = JSON.stringify(response.data);
    return resdata;
  };

  useEffect(() => {
    const searchResult = query?.get("q") || "";
    if (searchResult) {
      setDomainSearch(searchResult);
    } else {
      setDomainSearch("");
    }
    manageDomainSearch({ value: searchResult });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { domainSearch, domainsList, debouncedDomainSearch };
};
