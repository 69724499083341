import {
  setContext,
  ApolloClient,
  WebSocketLink,
  HttpLink,
  split,
  getMainDefinition,
  InMemoryCache,
  onError,
} from "~/apollo-dependencies";
import { Auth } from "aws-amplify";

const cache = new InMemoryCache();

const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL;
const httpLink = new HttpLink({
  uri: `https://${GRAPHQL_URL}/v1/graphql`, // use https for secure endpoint
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, extensions }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${extensions.code}`
        );
      });
    if (networkError) {
      switch (networkError.message) {
        case "cannot start as connection_init failed with : Could not verify JWT: JWTExpired":
          Auth.currentSession()
            .then((currSession) => {
              const idToken = currSession.getIdToken();
              const jwt = idToken.getJwtToken();
              operation.setContext({
                headers: {
                  Authorization: jwt ? `Bearer ${jwt}` : "",
                },
              });
              // retry the request, returning the new observable
              return forward(operation);
            })
            .catch((error) => {
              console.log(error);
            });
          break;
        default:
          break;
      }
    }
  }
);
// Create a WebSocket link:
const wsLink = new WebSocketLink({
  // TODO: cuando lo probamos en local, no podemos usar wss porque en local no usamos https, hay que condicionar que al usarlo en local use ws y en prod use wss
  uri: `wss://${GRAPHQL_URL}/v1/graphql`, // use wss for a secure endpoint
  options: {
    lazy: true,
    reconnect: true,
    timeout: 30000,
    connectionParams: async () => {
      try {
        const currSession = await Auth.currentSession();
        let idToken = currSession.getIdToken();
        let jwt = idToken.getJwtToken();
        return {
          headers: {
            Authorization: jwt ? `Bearer ${jwt}` : "",
          },
        };
      } catch (error) {
        console.log(error);
        // si no hay usuario conectado, no mandamos los headers
      }
    },
  },
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  httpLink
);

const authLink = setContext(async (_, { headers }) => {
  try {
    const currSession = await Auth.currentSession();
    let idToken = currSession.getIdToken();
    let jwt = idToken.getJwtToken();
    return {
      headers: {
        ...headers,
        Authorization: jwt ? `Bearer ${jwt}` : "",
      },
    };
  } catch (error) {
    console.log(error);
    // cuando no puede deolver un resultado de auth.currentSession, no necesitamos los headers
  }
});

// Isolate Apollo client so it could be reused
// in both application runtime and tests.
export const client = new ApolloClient({
  cache,
  link: errorLink.concat(authLink.concat(link)),
});
