import { useState } from "react";
import { useUpdateUserAccountHelpers } from "~/hooks/auth/useUpdateUserAccountHelpers";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/solid";

export const ManagePasswordCard = () => {
  const {
    handleOnChange,
    password,
    setPassword,
    newPassword,
    setNewPassword,
    confirmPassword,
    setConfirmPassword,
    changePassword,
  } = useUpdateUserAccountHelpers();

  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6 max-w-medium">
      <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
        <div className="text-gray-600">
          <p className="font-medium text-lg">Password</p>
          <p>
            Keep your account safe and don't share this information with anyone
          </p>
        </div>

        <div className="lg:col-span-2">
          <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
            <div className="md:col-span-5">
              <label htmlFor="full_name">Current password</label>
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                name="password"
                onChange={(e) => handleOnChange(e, setPassword)}
                className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
              />
            </div>
            <div className="md:col-span-5">
              <label htmlFor="full_name">New password</label>
              <input
                type={showPassword ? "text" : "password"}
                value={newPassword}
                name="new_password"
                onChange={(e) => handleOnChange(e, setNewPassword)}
                className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
              />
            </div>
            <div className="md:col-span-5">
              <label htmlFor="full_name">Confirm new password</label>
              <input
                type={showPassword ? "text" : "password"}
                value={confirmPassword}
                name="confirm_password"
                onChange={(e) => handleOnChange(e, setConfirmPassword)}
                className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
              />
            </div>
            <div className="md:col-span-5 text-right">
              <div className="inline-flex items-end">
                <button
                  onClick={changePassword}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Change password
                </button>
                <button
                  className="ml-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 rounded"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <EyeIcon className="w-10 h-5 px-2 text-white" />
                  ) : (
                    <EyeOffIcon className="w-10 h-5 px-2 text-white" />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
