import { gql, useQuery } from "~/apollo-dependencies";

const GET_INVOICES = gql`
  query getInvoices {
    invoices {
      date
      total
    }
  }
`;

export const useInvoices = (options) => {
  const query = useQuery(GET_INVOICES, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    ...options,
  });

  const invoices = query?.data?.invoices;

  return { query, invoices };
};
