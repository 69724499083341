import Li from "./Li";
import {
  EyeAllSee,
  ProductsAndServices,
  Invoices,
} from "~/atoms/icons/CustomIcons";

const SideNav = () => {
  return (
    <div className="flex flex-col flex-auto flex-shrink-0 antialiased bg-gray-50 text-gray-800">
      <div className="relative md:absolute flex flex-col md:left-0 md:w-68 bg-white h-full border-r-2 border-gray-100">
        <div className="flex-grow">
          <ul className="flex flex-col space-y-1 sticky top-0">
            <Li link="/settings" Icon={EyeAllSee} text="MY ACCOUNT" />
            <Li
              link="/settings/subscriptions-services"
              Icon={ProductsAndServices}
              text="SUBSCRIPTIONS / SERVICES"
            />
            <Li link="/settings/billing" Icon={Invoices} text="BILLING" />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideNav;
