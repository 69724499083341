import { useState, Fragment } from "react";
import { Magnifier } from "~/atoms/icons/CustomIcons";
import { useSearchDomain } from "~/hooks/domains/useSearchDomain";
import { Popover, Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { useOpenDrawer } from "~/molecules/temporaryDrawer";

const people = [
  { name: "Wade Cooper" },
  { name: "Arlene Mccoy" },
  { name: "Devon Webb" },
  { name: "Tom Cook" },
  { name: "Tanya Fox" },
  { name: "Hellen Schmidt" },
];

const userHasContacts = true;

export const SearchDomain = () => {
  const { domainSearch, domainsList, debouncedDomainSearch } =
    useSearchDomain();

  return (
    <>
      <div
        className={`wrapper px-2 flex justify-center ${
          domainSearch ? "items-top" : "items-center h-screen"
        } `}
      >
        <div className={`${domainSearch ? "" : "-mt-60"}`}>
          <h1
            className={`text-center tracking-widest font-semibold ${
              domainSearch ? "text-2xl mb-2" : "text-4xl mb-4"
            } mt-3 text-gray-600`}
          >
            Looking To Register A Domain?
          </h1>
          <form action="" className="max-w-sm bg-gray-100 px-3 py-5 m-auto">
            <div className="flex flex-col space-y-3">
              <div className="flex items-center bg-white border border-gray-100 rounded px-2">
                <Magnifier />
                <input
                  type="text"
                  placeholder="Search for domains"
                  aria-label="domain-search-input"
                  onChange={(e) =>
                    debouncedDomainSearch({ value: e.target.value })
                  }
                  className="w-full py-2 px-1 placeholder-indigo-400 outline-none placeholder-opacity-50"
                  autoComplete="off"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      {domainSearch && (
        <section
          data-testid="domainResultsTable"
          className="grid grid-cols-4 gap-4 justify-items-stretch border-gray-200 bg-white pt-3 pb-3"
        >
          {domainsList.map(
            ([key, value]: [key: string, value: { status: string }]) => {
              return (
                <DomainInner
                  key={key}
                  domain={key}
                  available={value?.status === "available"}
                />
              );
            }
          )}
        </section>
      )}
    </>
  );
};
// eslint-typescript-ignore

const DomainInner = ({
  domain,
  available,
}: {
  domain: string;
  available: boolean;
}) => {
  // let history = useHistory();
  const { toggleDrawer } = useOpenDrawer();
  const [selected, setSelected] = useState(people[0]);
  const [registerStep, setRegisterStep] = useState(0);
  const [registerForMyself, setRegisterForMyself] = useState(false);
  const [registerForClient, setRegisterForClient] = useState(false);

  return (
    <>
      {available ? (
        <Popover className="relative">
          <Popover.Button>
            <div className="flex justify-evenly cursor-pointer w-60">
              <p className={`text-sm text-gray-900`}>{`${domain} `}</p>
              <p className="text-sm text-vtn-violet">$20.00</p>
            </div>
          </Popover.Button>
          <Popover.Panel className="absolute z-10 -mt-10 -ml-4">
            <div className="bg-white rounded shadow p-4 m-4 max-w-xs max-h-full overflow-y-scroll">
              <p className={`text-gray-900 text-xl mb-2`}>
                {`${domain} `}{" "}
                <span className="text-vtn-violet text-xl">$20.00</span>
              </p>
              {registerStep === 0 && (
                <>
                  <p className="mb-1">
                    For whom do you want to register this domain?
                  </p>
                  <div className="grid lg:grid-cols-2 gap-6">
                    {/* eslint-disable-next-line */}
                    <button
                      onClick={() => setRegisterForMyself(true)}
                      className={`flex-no-shrink mt-4 pb-1 px-2 rounded text-white bg-vtn-violet hover:bg-teal-dark`}
                    >
                      For myself
                    </button>
                    <button
                      onClick={() => {
                        setRegisterForClient(true);
                        setRegisterStep(1);
                      }}
                      className={`flex-no-shrink mt-4 pb-1 px-2 rounded text-white bg-vtn-violet hover:bg-teal-dark`}
                    >
                      For a client
                    </button>
                  </div>
                </>
              )}
              {registerStep === 1 && registerForClient && userHasContacts && (
                <UserHasContactsFlow
                  selected={selected}
                  setSelected={setSelected}
                />
              )}
              {registerStep === 1 && registerForClient && !userHasContacts && (
                <>
                  <p className="mb-3">No contacts created yet</p>
                  <button
                    onClick={toggleDrawer()}
                    className={`flex-no-shrink mt-4 pb-1 px-2 rounded text-white bg-vtn-violet hover:bg-teal-dark`}
                  >
                    create contact
                  </button>
                </>
              )}
            </div>
          </Popover.Panel>
        </Popover>
      ) : (
        <div className="flex justify-evenly cursor-pointer">
          <p className={`text-sm text-gray-900 line-through`}>{domain}</p>
        </div>
      )}
    </>
  );
};

const UserHasContactsFlow = ({ selected, setSelected }: any) => {
  return (
    <>
      <p className="mb-1">Select client to link domain with</p>
      <Listbox value={selected} onChange={setSelected}>
        <div className="relative mt-1">
          <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
            <span className="block truncate">{selected.name}</span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <SelectorIcon
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {people.map((person, personIdx) => (
                <Listbox.Option
                  key={personIdx}
                  className={({ active }) =>
                    `${active ? "text-amber-900 bg-amber-100" : "text-gray-900"}
                          cursor-default select-none relative py-2 pl-10 pr-4`
                  }
                  value={person}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`${
                          selected ? "font-medium" : "font-normal"
                        } block truncate`}
                      >
                        {person.name}
                      </span>
                      {selected ? (
                        <span
                          className={`${
                            active ? "text-amber-600" : "text-amber-600"
                          }
                                absolute inset-y-0 left-0 flex items-center pl-3`}
                        >
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      <button
        className={`flex-no-shrink mt-4 pb-1 px-2 rounded text-white bg-vtn-violet hover:bg-teal-dark`}
      >
        register
      </button>
    </>
  );
};
