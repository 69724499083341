import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { useAuthCache } from "~/state/AuthorizationProvider";
import { useAlert } from "react-alert";

export const useUpdateUserAccountHelpers = () => {
  const alert = useAlert();
  const history = useHistory();

  // state management for updating user email
  const [newEmail, setNewEmail] = useState("");
  const [confirmNewEmail, setConfirmNewEmail] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  // state management for updating user password
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { setUserEmail } = useAuthCache();
  useEffect(() => {});

  const updateEmail = async (newEmail, confirmNewEmail) => {
    if (newEmail === confirmNewEmail) {
      try {
        // varios alerts gives the effect of points summing up while time passes by, telling the user that something is happening
        const currUser = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(currUser, {
          email: newEmail,
        });
        history.push({
          pathname: "/settings",
          search: "?requires_confirm_email=true",
        });
        setUserEmail(newEmail);
        // Cleaning inputs
        setNewEmail("");
        setConfirmNewEmail("");
        alert.show("Check your email, you received a confirmation code");
        // update also database
        //   const updated = await update_user_email({
        //     variables: { id: sub, email: newEmail },
        //   });
      } catch (err) {
        alert.show(err);
      }
    } else {
      alert.show("Emails don't match");
    }
  };

  const verifyEmailChange = async () => {
    try {
      await Auth.verifyCurrentUserAttributeSubmit("email", confirmationCode);
      alert.show("Email verified and changed successfully");
      history.push("/settings");
    } catch (err) {
      console.log("err: ", err);
      alert.show(
        "It seems there was an error with your confirmation code, try again?"
      );
    }
  };

  const changePassword = async () => {
    if (newPassword === confirmPassword) {
      try {
        const currUser = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(currUser, password, newPassword);
        await Auth.signOut();
        alert.show("You must sign in with your new password");
      } catch (error) {
        switch (error.code) {
          case "InvalidParameterException":
            alert.show(
              "Password do not meet security standards, it must have more than 6 characters"
            );
            break;
          default:
            alert.show(error);
            break;
        }
      }
    } else {
      alert.show("Passwords don't match");
    }
  };

  const handleOnChange = (e, func, toUpper) => {
    const value = toUpper
      ? e.currentTarget.value.toUpperCase()
      : e.currentTarget.value;
    func(value);
  };

  return {
    handleOnChange,
    // email management
    updateEmail,
    newEmail,
    setNewEmail,
    confirmNewEmail,
    setConfirmNewEmail,
    confirmationCode,
    setConfirmationCode,
    verifyEmailChange,
    // password management
    password,
    setPassword,
    newPassword,
    setNewPassword,
    confirmPassword,
    setConfirmPassword,
    changePassword,
    //name management
  };
};
