import { H2 } from "~/atoms/typography/headings";
import { useSubscriptions } from "~/hooks/billing/useSubscriptions";
import { serviceIcon } from "~/helpers/functionHelpers";

export const SubscriptionsTable = () => {
  const { subscriptions } = useSubscriptions();

  if (!subscriptions) return "loading";
  return (
    <div className="min-h-screen bg-gray-100 flex justify-center min-w-full">
      <div className="align-middle inline-block sm:px-6 lg:px-8">
        <H2>Subscriptions / services</H2>
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table className="divide-y divide-gray-200 min-w-7xl">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Item
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Price
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Auto renew
                </th>
                <th scope="col" className="relative px-6 py-3">
                  <span className="sr-only">Edit</span>
                </th>
                <th scope="col" className="relative px-6 py-3"></th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {subscriptions.map((subscription, index) => (
                <tr key={`${subscription.name}-${index}`}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 h-10 w-10">
                        {serviceIcon(subscription.description)}
                      </div>
                      <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                          {subscription.name}
                        </div>
                        <div className="text-sm text-gray-500">
                          {subscription.description}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">
                      {subscription.price}
                    </div>
                    <div className="text-sm text-gray-500">
                      {subscription.frequency}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span
                      className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        subscription.auto_renew
                          ? `bg-green-100 text-green-800`
                          : `bg-red-100 text-red-800`
                      }`}
                    >
                      {subscription.auto_renew ? "Active" : "Inactive"}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {subscription.role}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <a
                      href="/"
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      Edit
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
