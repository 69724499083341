import { useUpdateUserAccountHelpers } from "~/hooks/auth/useUpdateUserAccountHelpers";
import { useAuthCache } from "~/state/AuthorizationProvider";
import { useParams } from "~/hooks/router/useParams";

export const ManageEmailCard = () => {
  const {
    handleOnChange,
    updateEmail,
    newEmail,
    setNewEmail,
    confirmNewEmail,
    setConfirmNewEmail,
    emailAlert,
    confirmationCode,
    setConfirmationCode,
    verifyEmailChange,
  } = useUpdateUserAccountHelpers();
  const { userEmail } = useAuthCache();
  const queryParams = useParams();

  if (queryParams?.requires_confirm_email) {
    return (
      <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6 max-w-medium">
        <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
          <div className="text-gray-600">
            <p className="font-medium text-lg">E-mail</p>
            <p>
              We sent a confirmation code to your new email, copy and place it
              to the right so we can finish changing your email.
            </p>
          </div>

          <div className="lg:col-span-2">
            <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
              <div className="md:col-span-5">
                <label htmlFor="full_name">
                  This is the email you need to confirm
                </label>
                <input
                  type="text"
                  disabled
                  name="user_email"
                  value={userEmail}
                  className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                />
              </div>
              <div className="md:col-span-5">
                <label htmlFor="full_name">Confirmation code</label>
                <input
                  type="text"
                  name="confirmation_code"
                  value={confirmationCode}
                  onChange={(e) => handleOnChange(e, setConfirmationCode)}
                  className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                />
              </div>
              <div className="md:col-span-5 text-right">
                <div className="inline-flex items-end">
                  <button
                    onClick={verifyEmailChange}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Confirm email change
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6 max-w-medium">
      <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
        <div className="text-gray-600">
          <p className="font-medium text-lg">E-mail</p>
          <p>See what's your current email and update it if necessary.</p>
        </div>

        <div className="lg:col-span-2">
          <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
            <div className="md:col-span-5">
              <label htmlFor="curr_email">Current Email</label>
              <input
                type="text"
                disabled
                name="curr_email"
                value={userEmail}
                className="h-10 border mt-1 rounded px-4 w-full disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
              />
            </div>
            <div className="md:col-span-5">
              <label htmlFor="new_email">New Email</label>
              <input
                type="text"
                name="new_email"
                value={newEmail}
                onChange={(e) => handleOnChange(e, setNewEmail)}
                className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
              />
            </div>
            <div className="md:col-span-5">
              <label htmlFor="confirm_new_email">Confirm new Email</label>
              <input
                type="text"
                name="confirm_new_email"
                value={confirmNewEmail}
                onChange={(e) => handleOnChange(e, setConfirmNewEmail)}
                className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
              />
            </div>
            <div className="md:col-span-5 text-right">
              <div className="inline-flex items-end">
                <button
                  onClick={() => updateEmail(newEmail, confirmNewEmail)}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Change email
                </button>
                {emailAlert && emailAlert}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
