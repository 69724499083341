export const H1 = ({ children, className }) => {
  return (
    <h1 className={`${className} font-semibold text-4xl mt-3 text-gray-600`}>
      {children}
    </h1>
  );
};

export const H2 = ({ children, className }) => {
  return (
    <h2
      className={`${className} font-semibold text-xl mb-6 mt-3 text-gray-600`}
    >
      {children}
    </h2>
  );
};
