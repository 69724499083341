export const Input = ({ labelName }: any) => {
  return (
    <div className="border focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
      <div className="-mt-4 absolute tracking-wider px-1 uppercase text-xs">
        <p>
          <label htmlFor="name" className="bg-white text-gray-600 px-1">
            {labelName[0].toUpperCase()}
            {labelName.slice(1)} *
          </label>
        </p>
      </div>
      <p>
        <input
          name={labelName}
          autoComplete="false"
          tabIndex={0}
          type="text"
          className="py-1 px-1 text-gray-900 outline-none block h-full w-full"
        />
      </p>
    </div>
  );
};
