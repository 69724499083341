import { gql, useQuery } from "~/apollo-dependencies";

const GET_SUBSCRIPTIONS = gql`
  query GetSubscriptions {
    subscriptions {
      name
      description
      price
      frequency
      image
      auto_renew
    }
  }
`;

export const useSubscriptions = (options) => {
  const query = useQuery(GET_SUBSCRIPTIONS, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    ...options,
  });

  const subscriptions = query?.data?.subscriptions;

  return { query, subscriptions };
};
