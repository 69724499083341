import { useEffect, useState, createContext, useContext } from "react";
import { Auth, Hub } from "aws-amplify";
import { useUpsertUser } from "~/hooks/auth/useUpsertUser";

import { useHistory } from "react-router-dom";

export const AuthorizationContext = createContext();

export const AuthorizationProvider = ({ children }) => {
  const history = useHistory();
  const [userEmail, setUserEmail] = useState("");
  const { upsert_user } = useUpsertUser();
  // const [sub, setSub] = useState(null);
  // const [role, setRole] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    checkLoggedIn();
    listen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const listen = async () => {
    Hub.listen("auth", async (data) => {
      //  payload es lo que recibimos del evento de auth
      const { payload } = data;
      console.log(payload);
      // si hacen signout, ponemos el estado de user como "Log In"
      if (payload.event === "signOut") {
        setIsLoggedIn(false);
        history.push("/login");
        // setSub(null);
        // setRole(null);
        // setUserName(false);
        // si hacen sign in, colocamos el correo electrónico
      } else if (payload.event === "signIn") {
        // tomamos el usuario con la api de amplify Auth
        try {
          const userData = await Auth.currentAuthenticatedUser();

          const userId = userData.attributes.sub;
          const email = userData.attributes.email;
          setUserEmail(email);
          // const username = userData.username;
          setIsLoggedIn(true);
          history.push("/");
          // setUserName(userData.attributes.email);
          // setSub(userData.attributes.sub);
          // setRole(userData.attributes["custom:role"]);
          const res = await upsert_user({
            variables: {
              id: userId,
              email: email,
            },
          });
          console.log(res);
        } catch (error) {
          console.log(error);
        }
      }
    });
  };
  const checkLoggedIn = async () => {
    try {
      const userData = await Auth.currentAuthenticatedUser();
      setIsLoggedIn(true);
      setUserEmail(userData.attributes.email);
    } catch (error) {
      setIsLoggedIn(false);
      history.push("/login");
    }
  };

  return (
    <AuthorizationContext.Provider
      value={{ isLoggedIn, userEmail, setUserEmail }}
    >
      {children}
    </AuthorizationContext.Provider>
  );
};

export const useAuthCache = () => useContext(AuthorizationContext);
