import { H2 } from "~/atoms/typography/headings";
import { useInvoices } from "~/hooks/invoices/useInvoices";
import { Calendar } from "~/atoms/icons/CustomIcons";

export const InvoicesTable = () => {
  const { invoices } = useInvoices();
  console.log("invoices: ", invoices);
  if (!invoices) return "loading";
  return (
    <div className="bg-gray-100">
      <div className="py-2 align-middle inline-block grow  sm:px-6 lg:px-8">
        <H2>Invoices</H2>
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg max-w-xs">
          <table className="min-w-full divide-y divide-gray-200">
            <tbody className="bg-white divide-y divide-gray-200">
              {invoices.map((invoice, index) => {
                const date = new Date(invoice.date).toLocaleDateString(
                  "en-gb",
                  {
                    month: "long",
                    timeZone: "utc",
                  }
                ); // 18/02/2019
                return (
                  <tr key={`${invoice.date}-${index}`}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-10 w-10">
                          <Calendar />
                        </div>
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">
                            {date}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {invoice.role}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap max-w-xxxs">
                      <div className="text-sm text-gray-900">
                        {invoice.total}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <a
                        href="/"
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        View details
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
