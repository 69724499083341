import { gql, useMutation } from "~/apollo-dependencies";

const UPDATE_PROFILE_NAME = gql`
  mutation updateProfileName($id: uuid!, $fullname: String) {
    update_profile_by_pk(
      pk_columns: { id: $id }
      _set: { fullname: $fullname }
    ) {
      fullname
    }
  }
`;

export const useUpdateProfileName = () => {
  const [update_profile_name, query] = useMutation(UPDATE_PROFILE_NAME);

  return { update_profile_name, query };
};
