import Drawer from "@mui/material/Drawer";
import { atom, useRecoilState } from "recoil";

const drawerState = atom({
  key: "drawerState", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

export const useOpenDrawer = () => {
  const [opened, setOpened] = useRecoilState(drawerState);

  const toggleDrawer = () => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpened(!opened);
  };

  return { drawerState, toggleDrawer, opened };
};

export const TemporaryDrawer = ({ children }) => {
  const { toggleDrawer, opened } = useOpenDrawer();

  return (
    <>
      <Drawer anchor={"right"} open={opened} onClose={toggleDrawer()}>
        {children}
      </Drawer>
    </>
  );
};
