import { NavLink } from "react-router-dom";

const Li = ({ link, text, exact = true }) => {
  return (
    <NavLink
      to={link}
      exact={exact}
      activeClassName="text-gray-900 text-base border-indigo-500 border-b-4"
      className="text-gray-500 text-base hover:text-gray-900 pb-1 mt-1"
    >
      {text}
    </NavLink>
  );
};

export default Li;
