import SideNav from "~/organisms/sideNav";

export const Dashboard = ({ children }) => {
  return (
    <div className="min-h-screen py-8 px-8 sm:px-20 lg:px-80 bg-gray-100 min-w-full">
      {children}
    </div>
  );
};

export const DashboardSidebar = ({ children }) => {
  return (
    <>
      <SideNav />
      <div className="min-h-screen bg-gray-100 md:pl-60 py-8 flex justify-center">
        {children}
      </div>
    </>
  );
};
