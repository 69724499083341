import Li from "./Li";
import { useGetUserInfo } from "~/hooks/auth/useUserInfo";

export const OwnerLinks = () => {
  const { userInfo } = useGetUserInfo();
  return (
    <>
      {userInfo?.role === "owner" && (
        <>
          <Li link="/payments" text="Payments" />
          <Li link="/clients" text="Clients" />
          <Li link="/products" text="Products" />
        </>
      )}
    </>
  );
};
