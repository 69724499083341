/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { AccountDropDown } from "~/organisms/topNav/accountDropDown";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import Li from "./Li";
import { ClientLinks } from "./clientLinks";
import { OwnerLinks } from "./ownerLinks";
import { useAuthCache } from "~/state/AuthorizationProvider";

export const TopNavClient = () => {
  const { isLoggedIn } = useAuthCache();
  if (!isLoggedIn) {
    return <></>;
  }
  return (
    <Popover className="relative bg-white">
      <div className="mx-auto px-12">
        <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to="/">
              <span className="sr-only">Overview</span>
              <img
                className="h-8 w-auto sm:h-10"
                src="/vtn-imagotipo.png"
                alt=""
              />
            </Link>
            <AccountDropDown />
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <div className="hidden md:flex space-x-10">
            <Li link="/overview" text="Overview" />
            <OwnerLinks />
            <ClientLinks />
            {/* since we want all sidebar elements to also activate settings topbar, exact is false here */}
            <Li link="/settings" text="Settings" exact={false} />
          </div>
          <div className="hidden md:flex justify-end w-20">
            <Link
              to="/"
              className="whitespace-nowrap text-base py-2 font-medium text-gray-500 hover:text-gray-900"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right z-10 md:hidden"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <img
                    className="h-8 w-auto"
                    src="/vtn-imagotipo.png"
                    alt="Workflow"
                  />
                </div>
                <div className="mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8"></nav>
              </div>
            </div>
            <div className="py-10 px-5 space-y-6">
              <div className="flex justify-evenly flex-col gap-y-4">
                <Li link="/overview" text="Overview" />
                <OwnerLinks />
                <ClientLinks />
                {/* since we want all sidebar elements to also activate settings topbar, exact is false here */}
                <Li link="/settings" text="Settings" exact={false} />
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
