import { NavLink } from "react-router-dom";
const Li = ({ Icon, link, text }) => {
  return (
    <li>
      <NavLink
        to={link}
        exact
        activeClassName="outline-none bg-gray-50 text-gray-800 border-indigo-500"
        className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6"
      >
        <span className="inline-flex justify-center items-center ml-4">
          <Icon />
        </span>
        <span className="ml-2 text-sm tracking-wide truncate">{text}</span>
      </NavLink>
    </li>
  );
};

export default Li;
