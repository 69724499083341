import { Cpanel, Domain } from "~/atoms/icons/CustomIcons";
export const DomainCard = () => {
  return (
    <div className="sm:w-1/4 p-2">
      <div className="bg-white px-6 py-8 rounded-lg shadow-lg text-center">
        <div className="mb-3">
          <Domain className={`mx-auto`} />
        </div>
        <h2 className="text-xl font-medium text-gray-700">Domain</h2>
        <p className="block mb-5">
          Register a domain, use it to place your website or to host your own
          email@mydomain.com
        </p>
      </div>
    </div>
  );
};

export const CpanelCard = () => {
  return (
    <div className="sm:w-1/4 p-2">
      <div className="bg-white px-6 py-8 rounded-lg shadow-lg text-center">
        <div className="mb-3">
          <Cpanel className={`mx-auto`} />
        </div>
        <h2 className="text-xl font-medium text-gray-700">cPanel</h2>
        <p className="block mb-5">
          cPanel is a web hosting control panel software that provides a
          graphical interface (GUI)
        </p>
      </div>
    </div>
  );
};
