import {
  useLocation,
  useParams as useReactRouterDomParams,
} from "react-router-dom";

import queryString from "querystring";

export const useParams = (): Record<string, string> => {
  const location = useLocation();
  const params = useReactRouterDomParams();
  const queryParams = queryString.parse(location.search.substring(1));

  return {
    ...queryParams,
    ...params,
  };
};
