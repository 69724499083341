import { useEffect, useState } from "react";
import { useGetUserInfo } from "~/hooks/auth/useUserInfo";
import { useUpdateProfileName } from "~/hooks/profile/useUpdateProfile";
import { useAlert } from "react-alert";

export const PersonalDetailsCard = () => {
  const alert = useAlert();

  const { userProfile } = useGetUserInfo();
  const fullname = userProfile?.fullname;
  const profileId = userProfile?.id;

  const { update_profile_name, query } = useUpdateProfileName();

  const [fullName, setFullName] = useState(fullname);

  const updateProfileName = async (e) => {
    await update_profile_name({
      variables: { id: profileId, fullname: fullName },
    });
    alert.show("name updated successfully");
  };

  useEffect(() => {
    setFullName(fullname);
  }, []);

  return (
    <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6 max-w-medium">
      <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
        <div className="text-gray-600">
          <p className="font-medium text-lg">Personal Details</p>
          <p>
            We will use this information only to refer to you inside the
            platform.
          </p>
        </div>

        <div className="lg:col-span-2">
          <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
            <div className="md:col-span-5">
              <label htmlFor="full_name">Full Name</label>
              <input
                type="text"
                name="full_name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
              />
            </div>
            <div className="md:col-span-5 text-right">
              <div className="inline-flex items-end">
                <button
                  onClick={updateProfileName}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  {query.loading ? "Updating your name" : "Update your name"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
